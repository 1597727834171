import React from 'react';

import ToolbarContainer from '../components/ToolbarContainer';
import AccountLoginItem from '../components/AccountLoginItem';
import QRContainer from '../components/QRContainer';
import MFAContainer from '../components/MFAContainer';

import { IonImg, IonButton, IonPopover } from '@ionic/react';
import { IonRow, IonCol } from '@ionic/react';

/**/
import { IonIcon, IonLabel, IonInput } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import axios from 'axios';
/**/

import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getPlatforms } from '@ionic/react';

import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Account, getAccounts, getServices } from '../data/accounts';

import {
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';

import { IonCard, IonCardHeader, IonCardTitle, IonNote, IonCardContent } from '@ionic/react';
import './Aavis.css';
import '../components/QRContainer.css';

interface ContainerProps {
  loggedIn: boolean;
}

const Aavis: React.FC<ContainerProps> = ( props:ContainerProps ) => {

  const loggedIn:boolean = props.loggedIn;
  const { user } = useParams<{ user: string; }>();
  /**/
  const { time } = useParams<{ time: string; }>();
  /**/

  const [accounts, setAccounts] = useState<Account[]>([]);

  const [stop, setStop] = useState(false);
  const [platform, setPlatform] = useState<any>([]);


  const history = useHistory();

/*
  useIonViewWillEnter(() => {
    const accts = getAccounts();
    setAccounts(accts);
  });
*/

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  useEffect(() => {
    
    async function loadAccounts() {
      const accts = getAccounts(getServices()[3].id); // QR_TYPE_WEBSITE_LOGIN
      setAccounts(accts);
    }

    loadAccounts();

    setPlatform(getPlatforms());
    
    console.log("AAvis stop:" + stop);

   }, [stop])

  
  return (
    <IonPage id="accounts-page">

     {/*
      <IonHeader>
        <IonToolbar color="primary">
          <ToolbarContainer />
          <IonTitle class="ion-text-center">
            {getServices()[3].name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
    */}

      <IonContent fullscreen>

        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonCard>
          <IonCardHeader>

            {/*
            <IonCardTitle>{platform}</IonCardTitle> 
            
            {!platform.includes("android") ? (

              <IonCardTitle>DESKTOP</IonCardTitle> 
            ) : (
              <IonCardTitle>MOBILE</IonCardTitle> 
            )}
            */}
            
            {loggedIn ? (
              <>

              <IonCardTitle className="login-title-red ion-text-center">Welcome to AAVIS, {user}</IonCardTitle>
 
              {time ? (
                <IonCardTitle className="login-title-red ion-text-center">Congratulations, your {time} second login was a new PERSONAL BEST!</IonCardTitle>
              ) : (
                <></>
              )}

              </>

            ) : (
              <IonCardTitle className="login-title ion-text-center">Log into your AAVIS Account</IonCardTitle>
            )}
            <br/>
          </IonCardHeader>
          <IonCardContent>
                   
            {/*
            <IonButton className="loginbutton" id="login-trigger" >
              Login
            </IonButton>
            */}
            

            {!platform.includes("android") ? (
              <>
              <IonImg className="mockup-page" src="assets/wallpaper/aavis_rental_desktop.png" />

              {loggedIn ? (
                <IonButton className="loginbutton-desktop" fill="clear" onClick={() => {
                                                          history.push("/Aavis");
                                                        } }>
                Logout
                </IonButton>
              ) : (
                <IonButton className="loginbutton-desktop" id="login-trigger" fill="clear" >
                Login
                </IonButton>
              )}

              <IonPopover className="login-popover-desktop"
                id="login-popover"
                trigger="login-trigger"
                dismissOnSelect={false}
                side="bottom"
                alignment="center"
                reference="trigger"
                /*
                onIonPopoverDidDismiss={() => {
                                                setStop(true);
                                              } }
              */
              >     

                <IonImg className="mockup-login-img" src="assets/wallpaper/login_options.png" />                                

                
                  <IonButton id="qr-trigger" className="secqrme-button-desktop" fill="clear" />

                  
                  <IonPopover className="qr-popover-desktop"
                              id="qr-popover"
                              trigger="qr-trigger"
                              dismissOnSelect={true}
                              side="bottom"
                              alignment="center"
                              reference="trigger">
                    
                    {/* Account for Jim (id=1) harcoded, "Jamie" used as an alias*/}
                    <QRContainer svc="2" id="1" link={false} stop={stop} />            
                       
                  </IonPopover>

                  <IonButton id="twofa-trigger" className="twofa-button-desktop" fill="clear" />

                  
                  <IonPopover className="twofa-popover-desktop"
                              id="twofa-popover"
                              trigger="twofa-trigger"
                              dismissOnSelect={false}
                              side="top"
                              alignment="center"
                              reference="trigger">


                    <MFAContainer factors={2} />    

                  </IonPopover>

                </IonPopover> 

              </>

            ) : (
              <>
              <IonImg className="mockup-page" src="assets/wallpaper/aavis_rental_mobile.png" />

              {loggedIn ? (
                <IonButton className="loginbutton-mobile" fill="clear" onClick={() => {
                                                          history.push("/Aavis");
                                                        } }>
                Logout
                </IonButton>
              ) : (
                <IonButton className="loginbutton-mobile" id="login-trigger" fill="clear" >
                Login
                </IonButton>
              )}

              <IonPopover className="login-popover-mobile"
                id="login-popover"
                trigger="login-trigger"
                dismissOnSelect={true}
                side="bottom"
                alignment="center"
                reference="trigger"
                /*
                onIonPopoverDidDismiss={() => {
                                                setStop(true);
                                              } }
                */
              >     

                <IonImg className="mockup-login-img" src="assets/wallpaper/login_options.png" />                                

                
                  <IonButton id="qr-trigger" className="secqrme-button-mobile" fill="clear" />

                  <IonPopover className="qr-popover-mobile"
                              id="qr-popover"
                              trigger="qr-trigger"
                              dismissOnSelect={false}
                              side="bottom"
                              alignment="center"
                              reference="trigger">
                                        
                      {/* Account for Jim (id=1) harcoded, "Jamie" used as an alias*/}
                      <QRContainer svc="2" id="1" link={true} stop={stop} />            
        
                  </IonPopover>
                

                  <IonButton id="twofa-trigger" className="twofa-button-mobile" fill="clear" />

                  <IonPopover className="twofa-popover-mobile"
                              id="twofa-popover"
                              trigger="twofa-trigger"
                              dismissOnSelect={false}
                              side="bottom"
                              alignment="center"
                              reference="trigger">
                               
                    <MFAContainer factors={2} />    

                  </IonPopover>
 
                </IonPopover> 

              </>

            )}

          </IonCardContent>
        </IonCard>

        {/*
        <IonCard> 
          <IonCardHeader>
            <IonCardTitle>SecQR.Me-Enabled Accounts:</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>

            <IonList>
              
              {accounts.map(a => {
                return (
                  <AccountLoginItem key={a.id} account={a} service={getServices()[3]}/>
                )
              })
              }

            </IonList>
          </IonCardContent>
        </IonCard>
        */}

      </IonContent>

    </IonPage>
  );
};

export default Aavis;
