import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Account, getAccount, getAccounts, getAccountsAll, Service, getServices } from '../data/accounts';
import {
  IonBackButton,
  IonButton,
  IonContent,
  IonImg,
  IonHeader,
  IonItem,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';

import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonToggle } from '@ionic/react';

import { IonIcon, IonLabel, IonInput } from '@ionic/react';
import { personCircle } from 'ionicons/icons';

import { useParams } from 'react-router';
import './MFAContainer.css';

import axios from 'axios';

const BASE_URL = "https://2fa.banjosneakers.com/api/";
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
api.defaults.headers.common["Content-Type"] = "application/json";


interface ContainerProps {
  factors: number;
}

const MFAContainer: React.FC<ContainerProps> = ( props:ContainerProps ) => {

  const history = useHistory();

  const factors = props.factors; // not implemented

  const [user, setUser] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  
  /**/
  /*  
  const [hidden, setHidden] = useState("");
  
  const [loginForm, setLoginForm] = useState({
    emailName: "",
    passwordName: "",
    tokenName: ""
  });
  /**/
  
  const [message, setMessage] = useState("Enter your credentials to login");
  const [isError, setIsError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {

    // With onIonChange, the last form input entered does not reliably get saved to state. 
    // On mobile device, useEffect() runs one less time and the last form input isn't saved.
    // This appears to only happen on a mobile device, not seeing it on desktop (!?).

    console.log("MFAContainer useEffect");

    if(isSubmitted)
    {
      setIsSubmitted(false);
      handleLogin();
    }

    if(isAuthed)
    {
      setIsAuthed(false);

      // reset the form
      setUser("");
      setEmail("");
      setPassword("");
      setToken("");
      setMessage("Enter your credentials to login");
      setIsError(false);
      
      setTimeout(() => {
       history.push("/AavisLoggedIn/" + user);   
      }, 1000); 
      
    }

    return() => {
      console.log("MFAContainer useEffect return: cleanup code");      

    }

  }, [isSubmitted, isAuthed, email, password, token]);


  const handleLogin = async() => {
  
    /**/
    /*
    // Grab the form fields directly, rather than rely on the useState variables. 
    // The last form input entered does not reliably get saved to state. 
    // This appears to only happen on a mobile device, not seeing it on desktop (!?).

    const emailElement: any = document.getElementById("emailInput");
    const email2 = emailElement.value;

    const passwordElement: any = document.getElementById("passwordInput");
    const password2 = passwordElement.value;

    const tokenElement: any = document.getElementById("tokenInput");
    const token2 = tokenElement.value;
    /**/

    const user = "";
    const user_id = "";
    const loginData = {
      user: user,
      user_id: user_id,
      email: email,
      password: password,
      token: token
    };

    /**/
    /*
    console.log("Here 0");

    console.log("user: " + loginData.user);
    console.log("user_id: " + loginData.user_id);
    console.log("email: " + loginData.email);
    console.log("password: " + loginData.password);
    console.log("token: " + loginData.token);
    

    alert("email: " + loginData.email +
          "\npassword: " + loginData.password +
          "\ntoken: " + loginData.token);
    /**/

    api.post("/auth/login", loginData)
      .then((res) => {
        
        /**/
        /*
        console.log("Auth Success: ");
        console.log(res);
        /**/

        loginData.user_id = res.data.user.id;
        
        loginData.user = res.data.user.name;
        setUser(loginData.user); // doesn't get set - timing issue?

        /**/
        /*
        console.log("Here 1: " + res.data.message);

        console.log("user: " + loginData.user);
        console.log("user_id: " + loginData.user_id);
        console.log("email: " + loginData.email);
        console.log("password: " + loginData.password);
        console.log("user_id: " + loginData.token);
        /**/
    
        api.post("/auth/otp/validate", loginData)
          .then((res) => {
        
            /**/
            /*
            console.log("2FA Success: ");
            console.log(res);
            /**/

            /**/
            /*
            console.log("Here 2: " + res.data.message);
            /**/

            setIsAuthed(true);
          })
          .catch((error) => {

            /**/
            /*
            console.log("Here 3: " + error);
            /**/

            setMessage("2FA failure!");
            setIsError(true);
          });
      })
      .catch((error) => {

        /**/
        /*
        console.log("Here 4: " + error);
        /**/

        setMessage("Auth failure!");
        setIsError(true);
      });
  };

  const dummy = true;
  
  /**/
  /*
  const handleFormChange = (e:any) =>
    setLoginForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  
  const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  function simulateMouseClick(element: any){
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })
      )
    );
  }

  // create a new keyboard event and set the key to "Enter"
  const event = new KeyboardEvent('keydown', {
    key: 'Enter',
    code: 'Enter',
    which: 13,
    keyCode: 13,
  });
  /**/



  return (
    <>
{/*
      <IonRow className="qrcode-row">
        <IonCol size="12">
          <div>

            {dummy ? (
              <>
*/}            
                <IonRow>
                    <IonCol className="view-account-qrcode">
                      <IonCard>
                        <IonCardContent>
                          <div>
{/*
                          { dummy ?
                            <>
*/}
                            <form 
                    
                            onSubmit={ (e) => {
                              e.preventDefault();
                              setMessage("Submitting...");
                              setIsSubmitted(true);
                              }
                            }
                            >

                            <IonRow>
                              <IonCol>

                                <IonItem>
                                  <IonLabel 
                                    position="floating"
                                    style={{ fontSize: "15px", color: "#0040ff" }} >
                                      Email
                                  </IonLabel>
                                  <IonInput
                                    id="emailInput"
                                    name="emailName"
                                    type="email"
                                    value={email}
                                    //onChange={handleFormChange}
                                    onIonInput={ (e) => {
                                      setEmail(e.detail.value!); 
                                    } }
                                    /*
                                    onIonChange={ (e) => {
                                      setEmail(e.detail.value!);
                                      const hiddenElement: any = document.getElementById("emailInput");                               
                                      simulateMouseClick(hiddenElement);
                                      hiddenElement.dispatchEvent(event);
                                      } 
                                    }
                                    */
                                  /> 
                                </IonItem>

                                <IonItem>
                                  <IonLabel 
                                    position="floating"
                                    style={{ fontSize: "15px", color: "#0040ff" }} >
                                      Password
                                  </IonLabel>
                                  <IonInput
                                    id="passwordInput"
                                    name="passwordName"
                                    type="password"
                                    value={password}
                                    //onChange={handleFormChange}
                                    onIonInput={ (e) => {
                                      setPassword(e.detail.value!); 
                                    } }
                                    /*
                                    onIonChange={ (e) => {
                                      setPassword(e.detail.value!);
                                      const hiddenElement: any = document.getElementById("passwordInput");
                                      simulateMouseClick(hiddenElement);
                                      hiddenElement.dispatchEvent(event);
                                      } 
                                    }
                                    */
                                  />
                                </IonItem>

                                <IonItem>
                                  <IonLabel 
                                    position="floating"
                                    style={{ fontSize: "15px", color: "#0040ff" }} >
                                      Authentication Code
                                  </IonLabel>
                                  <IonInput
                                    id="tokenInput"
                                    name="tokenName"
                                    type="text"
                                    value={token}
                                    //onChange={handleFormChange}
                                    onIonInput={ (e) => {
                                      setToken(e.detail.value!); 
                                    } }
                                    /*
                                    onIonChange={ (e) => {
                                      setToken(e.detail.value!); 
                                      const hiddenElement: any = document.getElementById("tokenInput");
                                      simulateMouseClick(hiddenElement);
                                      hiddenElement.dispatchEvent(event);
                                      }
                                    }
                                    */
                                  />
                                </IonItem>

                              </IonCol>
                            </IonRow>

                            <IonRow>
                              <IonCol>
                                
                                {/*
                                <IonInput 
                                  id="hiddenInput"
                                  disabled={false} 
                                  placeholder=""
                                />
                                */}
                                <br/>
                          
                                {isError ? (
                                  <p style={{ textAlign: "center", fontSize: "small", color: "#ff0000" }}>
                                    {message}
                                  </p>
                                ) : (
                                  <p style={{ textAlign: "center", fontSize: "small", color: "#000000" }}>
                                    {message}
                                  </p>
                                )}

                                <IonButton id="submitButton" expand="block" type="submit"
                                
                                onClick={(e) => {
                                  
                                  /**/
                                  /*
                                  e.currentTarget.focus();
                                  const emailElement: any = document.getElementById("emailInput");
                                  emailElement.blur();
                                  const passwordElement: any = document.getElementById("passwordInput");
                                  passwordElement.blur();
                                  const tokenElement: any = document.getElementById("tokenInput");
                                  tokenElement.blur();
                                  /**/

                                } }>
                                  Login
                                  
                                </IonButton>

                              </IonCol>
                            </IonRow>

                            </form>
{/*
                            </>
                            : 
                            <div />
                          }
*/}
                          </div> 
                        </IonCardContent>
                      </IonCard>
                  </IonCol>
                </IonRow>
{/*
              </>  
            ) : (
              <div />
            )}
         </div>

        </IonCol>
      </IonRow>
*/}      
    </>
  );
}

export default MFAContainer;
