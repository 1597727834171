import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Account, getAccount, getAccounts, getAccountsAll, Service, getServices } from '../data/accounts';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonImg,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';

import { IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonToggle } from '@ionic/react';
import QRCode from 'react-qr-code';

import { personCircle } from 'ionicons/icons';
import { useParams } from 'react-router';
import './QR.css';

import axios from 'axios';

/**/
//const URL_RAS = "http://54.241.230.68:3003";  //"http://192.168.1.215:3002";   
const URL_RAS = "http://banjosneakers.com:3002";  //"http://192.168.1.215:3002";   
const URL_LOCAL = "http://localhost:3002";  
const URL_RAS_QR = URL_RAS;
//const URL_RAS_QR = URL_LOCAL;

const MAX_RETRIES = 60;
const RETRY_TIME = 2000;
const SERVER_WAITING = "Waiting for server authentication..."
const SERVER_APPROVED = "Server approved authentication.";
const SERVER_REJECTED = "Server rejected authentication.";
const SERVER_REJECTED_PAUSE = SERVER_REJECTED+" ";
const SERVER_TIMEOUT = "Sever timeout.";

const QR_TYPE_NULL = 0;
const QR_TYPE_PLACEHOLDER = 9;
const QR_TYPE_WINDOWS11_LOGIN_PERSONAL = 1;
const QR_TYPE_WINDOWS11_LOGIN_ENTERPRISE = 2;
const QR_TYPE_WEBSITE_LOGIN = 3;
const QR_TYPE_WEBSITE_PURCHASE = 4;
const QR_TYPE_PHYSICAL_LOCK_OPEN = 5;
const QR_TYPE_VOTING_AUTHENTICATE_WEB = 6;

//const DUMMY_SECQRME_TYPE = QR_TYPE_WINDOWS11_LOGIN_PERSONAL; // 2 factor
//const DUMMY_SECQRME_TYPE = QR_TYPE_PLACEHOLDER; // 3 factor
const DUMMY_SECQRME_TYPE = QR_TYPE_WINDOWS11_LOGIN_ENTERPRISE; // 4 factor
/**/

/**/
const callQRServer = async(server: string) => {
  
  // call a server to get the qr code
  let resp = "";

  try {
    const response = await axios.get<string>(server);    
    resp = response.data; 
    console.log("callQRServer: " + resp)
  }
  catch {
    console.log("Error contacting server.")
  }    
  
  return resp;
}

const registerSPAYD = async(id: number, type: number) => {

  // fetch a SPAYD QR Code
  let resp = "";

  // call the server
  resp = await callQRServer(URL_RAS_QR + "/api/regqr?id=" + id + "&type=" + type);

  console.log("QR code registered: " + resp); 

  return resp;
}

const parseSPAYD = (qr: string) => {

    const qrCode = {
      ACC: "",
      ALTACC: "",
      AM: "",
      CC: "",
      DT: "",
      RF: "",
      RN: "",
      MSG: "",
      PT: "",
      CRC32: ""
    }

    //var test = "SPD*1.0*ACC:CZ1355000000000000222885*AM:250.00*CC:CZK*MSG:FOND HUMANITY CCK*X-VS:333";
    const partsArray = qr.split("*");

    //console.log(qr);

    const paLength = partsArray.length;
    for (let i = 0; i < paLength; i++) {
  
      //console.log(partsArray[i]);

      const keyArray = partsArray[i].split(":");

      //console.log(keyArray[0]);
      //console.log(keyArray[1]);

      if(keyArray[0] === "ACC") {
        qrCode.ACC = keyArray[1];
      } 
      else if(keyArray[0] === "ALT-ACC") {
        qrCode.ALTACC = keyArray[1];        
      } 
      else if(keyArray[0] === "AM") {
        qrCode.AM = keyArray[1];        
      } 
      else if(keyArray[0] === "CC") {
        qrCode.CC = keyArray[1];
      } 
      else if(keyArray[0] === "DT") {
        qrCode.DT = keyArray[1];
      }
      else if(keyArray[0] === "RF") {
        qrCode.RF = keyArray[1];
      }
      else if(keyArray[0] === "RN") {
        qrCode.RN = keyArray[1];
      }
      else if(keyArray[0] === "MSG") {
        qrCode.MSG = keyArray[1];
      }
      else if(keyArray[0] === "PT") {
        qrCode.PT = keyArray[1];
      }
      else if(keyArray[0] === "CRC32") {
        qrCode.CRC32 = keyArray[1];
      }
    }

    return qrCode;
  }
/**/



const QR: React.FC = () => {

  const [account, setAccount] = useState<Account>();
  const [fetchedQR, setFetchedQR] = useState(""); 
  const [transIDPending, setTransIDPending] = useState(""); 

  const [firstLoad, setFirstLoad] = useState(true);
  const [noInterval, setNoInterval] = useState(true);
  const [numberRetries, setNumberRetries] = useState(MAX_RETRIES);
  const [statusMessage, setStatusMessage] = useState(SERVER_WAITING);

  const { svc } = useParams<{ svc: string; }>();
  const { id } = useParams<{ id: string; }>();

  const history = useHistory();

  const getQR = async(acct:Account) => {
    const qr = await registerSPAYD(acct.id, Number(svc)/*DUMMY_SECQRME_TYPE*/);
    setFetchedQR(qr);
    const qrCode = parseSPAYD(qr);
    const tx = qrCode.MSG;
    setTransIDPending(tx);
  }

  /**/
  const callQRServer = async(server: string) => {
    
    // call a server to approve a transaction
    let resp = "";

    try {
      const response = await axios.get<string>(server);    
      resp = response.data; 
      console.log("callQRServer: " + resp)
    }
    catch {
      console.log("Error contacting server.")
    }    

    return resp;
  }

  const pendingTransaction = async(tx: string) => {

    // query the server whether the transaction is pending
    let resp = "";

    // call the server
    resp = await callQRServer(URL_RAS_QR + "/api/pending?tx=" + tx);

    console.log("Approval response: " + resp); 

    return resp;
  }
  /**/

  /**/
  /* RUNS TWICE - WHY?
  useIonViewWillEnter(() => {
    const acct = getAccount(parseInt(params.id, 10));
    if(acct) {
      setAccount(acct);
      
      if(fetchedQR === "")
      {
        getQR(acct);
      }
    }
  });
  /**/
  useEffect(() => {

    console.log("ViewAccount useEffect");

    let intervalID : any;
    let numRetries = MAX_RETRIES;

    const serverSend = async() => {
        
      console.log(numRetries + " attempts left");
     
      const result = await pendingTransaction(transIDPending);
      
      if(result === "NACK") {
        setStatusMessage(SERVER_APPROVED);
        console.log("NACK - cleared Interval: " + intervalID);
        clearInterval(intervalID);
      }
      else {
        setStatusMessage(SERVER_REJECTED);
      }

      numRetries--;
      setNumberRetries(numRetries);

      if(numRetries <= 0) {
        clearInterval(intervalID);
        console.log("Timeout - cleared Interval: " + intervalID);
      }

    }

    const getFromServer = async() => {
      
      intervalID = setInterval(() => {
        serverSend();
      }, RETRY_TIME);

      console.log("created Interval: " + intervalID);
    }

    if(firstLoad) {

      console.log("firstLoad:" + firstLoad);

      const acct = getAccount(parseInt(id, 10));
      if(acct) {
        setAccount(acct);
      }

      setFirstLoad(false);
    }

    if( (fetchedQR === "") && (account) )
    {
      getQR(account);
    }
    
    // set an API call interval if not already set and if the parameter is ready
    console.log("currentConfig.transIDPending:" + transIDPending);
    if( (transIDPending != "") &&
        (noInterval == true) ) {
      getFromServer()
        .catch(console.error);

      setNoInterval(false);
    }


    if(statusMessage === SERVER_APPROVED) {
      
      const previewElement: any = document.getElementById("message-status");
      if(previewElement) {
        previewElement.style.color = "#00FF00";
      }

      setTimeout(() => {
        //history.push("/Success");   
        alert("LOGGED IN");
      }, 2000); 
    }
    else if(statusMessage === SERVER_REJECTED_PAUSE) {
      /**/
      // How to get the GUI to pause on the rejected message ???
      /*
      setTimeout(() => {;
      }, 3000); 
      /**/
      setStatusMessage(SERVER_WAITING);
    }
    else if(statusMessage === SERVER_REJECTED) {
      
      const previewElement: any = document.getElementById("message-status");
      if(previewElement) {
        previewElement.style.color = "#FF0000";
      }
   
      console.log("SERVER_REJECTED numberRetries: " + numberRetries);
      if(numberRetries <= 0) {
        
        // no more chances, give user an exit
        setStatusMessage(SERVER_TIMEOUT);
        console.log("SERVER_REJECTED -> SERVER_TIMEOUT");
      }
      else {
        setStatusMessage(SERVER_REJECTED_PAUSE);
      }
    }
    else if(statusMessage === SERVER_WAITING) {

      console.log("SERVER_WAITING numberRetries: " + numberRetries);
      if(numberRetries <= 0) {
        // no more chances, move to a timeout
        setStatusMessage(SERVER_REJECTED);
        console.log("SERVER_WAITING -> SERVER_REJECTED");
      } 
      else {
        const previewElement: any = document.getElementById("message-status");
        if(previewElement) {
          previewElement.style.color = "#0000FF";
        }
      }
    }

    return() => {
      console.log("ViewAccount useEffect return: cleanup code");      
    }

  }, [account, fetchedQR, statusMessage]);


  return (
    <IonPage id="view-account-page">
      
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/Home"></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
        {account ? (
          <>
            <IonItem>
              <IonIcon aria-hidden="true" icon={personCircle} color="primary"></IonIcon>
              <IonLabel className="ion-text-wrap">
                <h2> {account.name} ( {account.user} ) </h2>
                <h3> {account.notes} </h3>
              </IonLabel>
            </IonItem>

            <div className="ion-padding">  
  
                <h1 className="redtext">{getServices()[svc as any].name}</h1>
                <h1 className="redtext">{getAccountsAll()[id as any].name}</h1>

                <br/>
                <h1>Please use the SecQR.Me app to login with this QR code:</h1>
                {/*
                <br/><IonNote>QR = {fetchedQR}</IonNote>
                */}
                <IonCard className="view-account-qrcode">
                  <IonCardContent>
                    <div className="ion-padding">
                      { fetchedQR ? 
                        <>
                          <IonCard>
                            <IonCardContent>
                              <QRCode value={fetchedQR} />
                            </IonCardContent>
                          </IonCard>
                        </>
                      : <IonImg src="assets/wallpaper/LoginScreen.png"/>
                      }

                    </div>
                  </IonCardContent>
                </IonCard>
            </div>
            <div className="view-account-qrcode">
              <h1>
                <IonGrid>

                  <IonRow className="ion-grid-status">
                    <IonCol size="12">            
                      <IonNote id="message-status">{statusMessage}</IonNote><br/>
                    </IonCol>
                  </IonRow>   
                </IonGrid>
              </h1>
            </div>

          </>
        ) : (
          <div>Account not found</div>
        )}
      </IonContent>

    </IonPage>
  );
}

export default QR;
