import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import ServicesMenu from './components/ServicesMenu';
import QR from './pages/QR';
import Home from './pages/Home';
import Win11Personal from './pages/Win11Personal';
import Win11Enterprise from './pages/Win11Enterprise';
import Aavis from './pages/Aavis';
import Tweeter from './pages/Tweeter';
import Rainforest from './pages/Rainforest';
import PhysicalLock from './pages/PhysicalLock';
import CastVote from './pages/CastVote';
import Null from './pages/Null';
import Placeholder from './pages/Placeholder';
/*
import ViewAccount from './pages/ViewAccount';
*/

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>

      <ServicesMenu />

      <IonSplitPane contentId="main">

        <IonRouterOutlet id="main">

          <Route path="/" exact={true}>
            <Redirect to="/Home" />
          </Route>

          <Route path="/QR/:svc/:id">
             <QR />
          </Route>

          <Route path="/Home" exact={true}>
            <Home />
          </Route>
          <Route path="/Win11Personal" exact={true}>
            <Win11Personal />
          </Route>
          <Route path="/Win11Enterprise" exact={true}>
            <Win11Enterprise />
          </Route>
          <Route path="/Aavis" exact={true}>
            <Aavis loggedIn={false} />
          </Route>
          <Route path="/AavisLoggedIn/:user" exact={true}>
            <Aavis loggedIn={true} />
          </Route>
          <Route path="/AavisLoggedIn/:user/:time" exact={true}>
            <Aavis loggedIn={true} />
          </Route>
          <Route path="/Tweeter" exact={true}>
            <Tweeter />
          </Route>
          <Route path="/Rainforest" exact={true}>
            <Rainforest />
          </Route>
          <Route path="/PhysicalLock" exact={true}>
            <PhysicalLock />
          </Route>
          <Route path="/CastVote" exact={true}>
            <CastVote />
          </Route>
          <Route path="/Null" exact={true}>
            <Null />
          </Route>
          <Route path="/Placeholder" exact={true}>
            <Placeholder />
          </Route>
          {/*
          <Route path="/Account/:id">
             <ViewAccount />
          </Route>
          */}

        </IonRouterOutlet>
    
        {/*
        <iframe src="http://0.0.0.0:50500/">
        </iframe>
        */}
    
      </IonSplitPane>

    </IonReactRouter>
  </IonApp>
);

export default App;
