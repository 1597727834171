import React from 'react';

import ToolbarContainer from '../components/ToolbarContainer';

import AccountLoginItem from '../components/AccountLoginItem';

import { useState, useEffect } from 'react';
import { Account, getAccounts, Service, getServices } from '../data/accounts';

import {
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';

import { IonCard, IonCardHeader, IonCardTitle, IonNote, IonCardContent } from '@ionic/react';
import './Win11Enterprise.css';

const Win11Enterprise: React.FC = () => {

  const [accounts, setAccounts] = useState<Account[]>([]);

/*
  useIonViewWillEnter(() => {
    const accts = getAccounts();
    setAccounts(accts);
  });
*/

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  useEffect(() => {

    async function loadAccounts() {
      const accts = getAccounts(getServices()[2].id); // QR_TYPE_WINDOWS11_LOGIN_ENTERPRISE
      setAccounts(accts);
    }

    loadAccounts();
   }, [])


  return (
    <IonPage id="accounts-page">

      <IonHeader>
        <IonToolbar color="primary">
          <ToolbarContainer />
          <IonTitle class="ion-text-center">
            {getServices()[2].name}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Unlock your PC with 4 Factor Authentication</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonNote>SecQR.Me-Enabled Accounts:</IonNote>
            
            <IonList>
              
              {accounts.map(a => {
                return (
                  <AccountLoginItem key={a.id} account={a} service={getServices()[2]}/>
                )
              })
              }

            </IonList>
          </IonCardContent>
        </IonCard>

      </IonContent>

    </IonPage>
  );
};

export default Win11Enterprise;
