import React from 'react';
import {
  IonItem,
  IonLabel,
  IonNote
  } from '@ionic/react';
import { Account } from '../data/accounts';
import { Post } from '../data/accounts';
import './PostListItem.css';

interface PostListItemProps {
  post: Post;
}

const PostListItem: React.FC<PostListItemProps> = ({ post }) => {
  return (
    <IonItem routerLink={`/account/${post.id}`} detail={false}>
      
      <IonLabel className="ion-text-wrap">
        <h2>
          {post.id}
        </h2>
        <h3>{post.id}</h3>
        <h3>{post.slug}</h3>
        <h3>{post.title}</h3>
        <h3>{post.content}</h3>
      </IonLabel>
    </IonItem>
  );
};

export default PostListItem;
