import React from 'react';
import {
  IonItem,
  IonLabel,
  IonNote
  } from '@ionic/react';
import { Account, getAccounts, getServices } from '../data/accounts';
import './AccountListItem.css';

interface AccountListItemProps {
  account: Account;
}

const AccountListItem: React.FC<AccountListItemProps> = ({ account }) => {
  return (
    <IonItem /*routerLink={`/account/${account.id}`}*/ detail={false}>
      <div slot="start" className={"dot dot-logged dot-logged-" + (account.loggedIn ? "in" : "out")}></div>
      <IonLabel className="ion-text-wrap">
        <h2> {account.name} ( {account.user} ) </h2>
        <h3> {account.notes} </h3>
        {account.services.map(s => {
          return (
            <p className="servicelist" key={s}>{getServices()[s].name}</p>
          )
        })
        } 
      </IonLabel>
    </IonItem>
  );

};

export default AccountListItem;
