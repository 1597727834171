import React from 'react';

import ToolbarContainer from '../components/ToolbarContainer';

import AccountListItem from '../components/AccountListItem';
import PostListItem from '../components/PostListItem';

import { useState, useEffect } from 'react';
import { Account, getAccounts, getServices } from '../data/accounts';
import { Post } from '../data/accounts';

import {
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';

import { IonCard, IonCardHeader, IonCardTitle, IonNote, IonCardContent } from '@ionic/react';
import './Home.css';

const Home: React.FC = () => {

  const [accounts, setAccounts] = useState<Account[]>([]);

/*
  useIonViewWillEnter(() => {
    const accts = getAccounts();
    setAccounts(accts);
  });
*/

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

    
  const [posts, setPosts] = useState<any[]>([])

  useEffect(() => {
    
    async function loadPosts() {
      const response = await fetch('http://localhost:8081/wp-json/wp/v2/accounts');
      if(!response.ok) {
        return;
      }

      const posts = await response.json();
      setPosts(posts);
    }

    async function loadAccounts() {
      //const accts = getAccountsAll();
      const accts = getAccounts(getServices()[0].id); // QR_TYPE_NULL
      setAccounts(accts);
    }

    loadAccounts();
    /**/
    //loadPosts();
    /**/
   }, [])


  return (
    <IonPage id="accounts-page">

      <IonHeader>
        <IonToolbar color="primary">
          <ToolbarContainer />
          <IonTitle class="ion-text-center">
            Home - All Accounts
          </IonTitle>
        </IonToolbar>
        
      </IonHeader>

      <IonContent fullscreen>

        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Registered Accounts and Enabled Services</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonNote>SecQR.Me-Enabled Accounts:</IonNote>
            
            <IonList>
              
              {accounts.map(a => {
                return (
                  <AccountListItem key={a.id} account={a} />
                )
              })
              }
              
              {posts.map((post, index) => {

                const newPost:Post = {
                  id: post.id, 
                  slug: post.slug, 
                  title: post.title.rendered, 
                  content: post.content.rendered
                }
                
                return (
                  <>
                  <PostListItem key={index} post={newPost} />
                  {/* 
                  <IonItem key={index}>
                    {post.id} <br/>     
                    {post.slug} <br/>    
                    {post.title.rendered} <br/>
                    {post.content.rendered} <br/>            
                  </IonItem>
                  */}
                  </>
                )
              })
              }

            </IonList>
          </IonCardContent>
        </IonCard>

      </IonContent>

    </IonPage>
  );
};

export default Home;
