import React from 'react';
import {
  IonItem,
  IonLabel,
  IonNote
  } from '@ionic/react';
import { Account, getAccounts, Service, getServices } from '../data/accounts';
import './AccountLoginItem.css';

interface AccountLoginItemProps {
  account: Account;
  service: Service;
}

const AccountLoginItem: React.FC<AccountLoginItemProps> = ( props:AccountLoginItemProps ) => {
  return (
    <IonItem routerLink={`/QR/${props.service.id}/${props.account.id}`} detail={true}>
      <div slot="start" className={"dot dot-logged dot-logged-" + (props.account.loggedIn ? "in" : "out")}></div>
      <IonLabel className="ion-text-wrap">
        <h2> {props.account.name} ( {props.account.user} ) </h2>
        <h3> {props.account.notes} </h3>
        <IonLabel className="date">
          <IonNote>Last Logged In: </IonNote>
          <IonNote>
            { props.account.lastLoginTime.toLocaleDateString('en-us') === 
              new Date(0).toLocaleDateString('en-us') ?
                "Never" :
                props.account.lastLoginTime.toLocaleDateString('en-us')
            }
          </IonNote>
        </IonLabel>
      </IonLabel>
    </IonItem>
  );

};

export default AccountLoginItem;
