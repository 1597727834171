import './MenuWithSubsContainer.css';

import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { IonPopover, IonContent, IonItem, IonLabel, IonList, IonIcon } from '@ionic/react';
import { IonMenu, IonListHeader,IonMenuToggle, IonNote } from '@ionic/react';
import { menuController, popoverController } from '@ionic/core/components'

import { v4 as uuidv4 } from 'uuid';

export interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  sub: AppPage[] | null;
  configItem: boolean;
}

interface ContainerProps {
  id: string;
  key: string;
  header: string;
  note: string;
  pages: AppPage[];
}


export const MenuWithSubsContainer: React.FC<ContainerProps> = (props:ContainerProps) => {

  const location = useLocation();

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {

    console.log("MenuTest useEffect");

    const toggleMenu = async(toggle: boolean) => {
      await menuController.close(props.id);
      const popover = await popoverController.getTop();
      if(popover) {
        await popover.dismiss(null);
      }
    }

    const getFromStorage = async() => {
      //const cfg = await retrieveConfigItems();
      //setCurrentConfig(cfg);
      setFirstLoad(false);
    }

    if(firstLoad) {
      
      console.log("****** Menu First Load.");
      getFromStorage()
        .catch(console.error); 
    }
    else {

      console.log("********* Menu changed page, close menu.");
      toggleMenu(false);
    }

    return () => { 
      console.log("MenuTest useEffect return");
    }

  }, [location]);

  const dispatchMenuWithSubsClickEvent = (detail: string) => {
    const menuWithSubsClickEvent = new CustomEvent("custom:menuWithSubsClick", {detail: detail});
    console.log("Emitting Event: " + menuWithSubsClickEvent.type);
    window.dispatchEvent(menuWithSubsClickEvent);
  }

  return (
    <IonContent key={uuidv4()}>
      <IonList id={props.id + "-list"} key={uuidv4()} lines="none">
        
        <IonListHeader className="menu-header">{props.header}</IonListHeader>
        <IonNote className="menu-note">{props.note}</IonNote>    

        {props.pages.map((page, index) => {
          return (
            <>
            {/*<IonMenuToggle menu={props.id} key={index} autoHide={false}> */}

              {(page.sub == null) ?  
                <IonItem id={props.id + "-item-" + index}
                          key={uuidv4()}
                          lines="full" 
                          button={false}
                          detail={false}
                          className={location.pathname === page.url ? 'selected' : ''} 
                          routerLink={page.url} 
                          routerDirection="forward" 
                          onClick={() => dispatchMenuWithSubsClickEvent(page.url)}
                >
                  {page.mdIcon != '' &&
                    <IonIcon slot="start" md={page.mdIcon} />
                  }
                  <IonLabel text-wrap="true">{page.title}</IonLabel>
                </IonItem>                       
                :
                // include sub-menus in a popover
                <>
                <IonItem  id={"options-trigger-" + index}
                          key={uuidv4()}
                          lines="full" 
                          button={true}
                          detail={true}
                          //onClick={() => alert("!")}
                >
                {page.mdIcon != '' &&
                  <IonIcon slot="start" md={page.mdIcon} />
                }
                  <IonLabel text-wrap="true">{page.title}</IonLabel>
                </IonItem>

                <IonPopover key={uuidv4()}
                            trigger={"options-trigger-" + index} 
                            dismissOnSelect={false}
                            slot="end">                         
                  <MenuWithSubsContainer  id={props.id} 
                                          key={uuidv4()}
                                          header={page.title}
                                          note={props.note}
                                          pages={page.sub} />          
                </IonPopover>
                </>
              }
            
            {/*</IonMenuToggle>*/}
            </>
          );          
        })}
    
      </IonList>
    </IonContent>
  );
};

/**/
/* why doesn't this work?
export default MenuWithSubsContainer;
*/