import React from 'react';

import './ToolbarContainer.css';

import { IonToolbar, IonButtons, IonButton, IonIcon, IonLabel, IonContent, IonHeader, IonMenuButton, IonPopover } from '@ionic/react';
import { options, settingsSharp, menuSharp } from 'ionicons/icons';

import { IonMenu, IonMenuToggle } from '@ionic/react';

import { IonRow, IonCol } from '@ionic/react';
import { useParams } from 'react-router';

const ToolbarContainer: React.FC = () => {

  return (
    <IonButtons slot="end">
      <IonMenuButton id="services-menu-button" menu="services-menu">
        <IonIcon icon={settingsSharp} />
      </IonMenuButton>
      <IonLabel>Services</IonLabel>
    </IonButtons>
  );
};

export default ToolbarContainer;
