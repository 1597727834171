export interface Account {
  name: string;
  user: string;
  password: string;
  loggedIn: boolean;
  lastLoginTime: Date;
  notes: string;
  id: number;
  services: Array<number>; // match to index in Services array
}

export interface Post {
  id: string;
  slug: string;
  title: string;
  content: string;
}

const accounts: Account[] = [
  {
    name: 'Guest',
    user: 'guest',
    password: 'guestpass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "Guest account, shared by authorized temporary users",
    id: 0, // maps to SecQR.Me user account "T3$TACNT"
    services: [ 1, 2, 3, 4, 5, 6, 9, 0 ]
  },
  {
    name: 'Jim Bennison',
    user: 'jbennison',
    password: 'jimpass',
    loggedIn: true,
    lastLoginTime: new Date(0),
    notes: "Creator of 4 factor authentication and SecQR.Me",
    id: 1, // maps to SecQR.Me user account "B3NN1S0N"
    services: [ 2, 3, 5, 9, 0 ]
  },
  {
    name: 'Jason Sebranek',
    user: 'jsebranek',
    password: 'jasonpass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "Developer of the SecQR.Me demo app",
    id: 2, // maps to SecQR.Me user account "S3BRAN3K"
    services: [ 1, 4, 6, 9, 0 ]
  },
  {
    name: 'George Washington',
    user: 'gwashington',
    password: 'georgepass',
    loggedIn: true,
    lastLoginTime: new Date(0),
    notes: "1st President",
    id: 3, // maps to SecQR.Me user account "GW@SH1NG"
    services: [ 1, 3, 5, 6, 9, 0 ]
  },
  {
    name: 'Abraham Lincoln',
    user: 'alincoln',
    password: 'abepass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "16th President",
    id: 4, // maps to SecQR.Me user account "@B3LNCN"
    services: [ 2, 4, 5, 6, 9, 0 ]
  },
  {
    name: 'Hot Desk 13',
    user: 'hotdesk13',
    password: 'hd13pass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "Allowed Users for Hot Desk 13",
    id: 5, // maps to SecQR.Me user account "H0TD$K13"
    services: [ 2, 0 ]
  },
  {
    name: 'Swing Space 27',
    user: 'swingspace27',
    password: 'ss21pass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "Allowed Users for Swing Space 27",
    id: 6, // maps to SecQR.Me user account "$WG$PC27"
    services: [ 2, 0 ]
  },
  {
    name: 'Jamie',
    user: 'jamie',
    password: 'jamiepass',
    loggedIn: true,
    lastLoginTime: new Date(0),
    notes: "SecQR.Me user of AAvis demo, alias for Jim Bennison",
    id: 7, // maps to SecQR.Me user account "B3NN1S0N"
    services: [ 2, 3, 5, 9, 0 ]
  },
  {
    name: 'Jonathan',
    user: 'jonathan',
    password: 'jonathanpass',
    loggedIn: false,
    lastLoginTime: new Date(0),
    notes: "2FA user of AAvis demo, alias for Jason Sebranek",
    id: 8, // maps to SecQR.Me user account "S3BRAN3K"
    services: [ 1, 4, 6, 9, 0 ]
  }

];

export interface Service {
  id: number; // match to index in Services array
  name: string;
  type: string;  
}

const services: Service[] = [
  {
    id: 0, 
    name: "Null",
    type: "QR_TYPE_NULL"
  },
  {
    id: 1,
    name: "Windows 11 Personal Login",
    type: "QR_TYPE_WINDOWS11_LOGIN_PERSONAL"
  },
  {
    id: 2,
    name: "Windows 11 Enterprise Login",
    type: "QR_TYPE_WINDOWS11_LOGIN_ENTERPRISE"
  },
  {
    id: 3,
    name: "Website Login",
    type: "QR_TYPE_WEBSITE_LOGIN"
  },
  {
    id: 4,
    name: "Website Purchase",
    type: "QR_TYPE_WEBSITE_PURCHASE"
  },
  {
    id: 5,
    name: "Open a Physical Lock",
    type: "QR_TYPE_PHYSICAL_LOCK_OPEN"
  },
  {
    id: 6,
    name: "Vote over the Web",
    type: "QR_TYPE_VOTING_AUTHENTICATE_WEB"
  },
  {
    id: 7,
    name: "Placeholder for Service #7",
    type: "QR_TYPE_PLACEHOLDER_7"
  },
  {
    id: 8,
    name: "Placeholder for Service #8",
    type: "QR_TYPE_PLACEHOLDER_8"
  },
  {
    id: 9,
    name: "Placeholder for 3 Factor",
    type: "QR_TYPE_PLACEHOLDER"
  },
];


export const getServices = () => services;

export const getAccountsAll = () => accounts;

export const getAccounts = (service: number) => accounts.filter(a => a.services.includes(service));

export const getAccount = (id: number) => accounts.find(a => a.id === id);

