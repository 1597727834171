import './ServicesMenu.css';

import React, { useState } from 'react';
import { IonMenu } from '@ionic/react';
import { MenuWithSubsContainer, AppPage } from '../components/MenuWithSubsContainer';

import { returnDownBack, returnDownBackOutline, returnDownBackSharp, checkmarkOutline, checkmarkSharp } from 'ionicons/icons';


const loginSubPages: AppPage[] = [
  {
    title: 'Windows 11 - Personal',
    url: '/Win11Personal',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'Windows 11 - Enterprise',
    url: '/Win11Enterprise',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'AAvis.com',
    url: '/Aavis',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'Tweeter',
    url: '/Tweeter',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  }
];

const paymentSubPages: AppPage[] = [
  {
    title: 'Rainforest.shop',
    url: '/Rainforest',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  }
];

const testSubPages: AppPage[] = [
  {
    title: 'NULL',
    url: '/Null',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'PLACEHOLDER',
    url: '/Placeholder',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  }
];

const appPages: AppPage[] = [
  {
    title: 'All',
    url: '/Home',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'Logins',
    url: '',
    iosIcon: '',
    mdIcon: '',
    sub: loginSubPages,
    configItem: false
  },
  {
    title: 'Payments',
    url: '',
    iosIcon: '',
    mdIcon: '',
    sub:paymentSubPages,
    configItem: false
  },
  {
    title: 'Open Physical Lock',
    url: '/PhysicalLock',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'Cast a Vote',
    url: '/CastVote',
    iosIcon: '',
    mdIcon: '',
    sub: null,
    configItem: false
  },
  {
    title: 'Test',
    url: '',
    iosIcon: '',
    mdIcon: '',
    sub: testSubPages,
    configItem: false
  },
  
];

const ServicesMenu: React.FC = () => {

  return (
    <IonMenu contentId="main" menuId="services-menu" side="end" type="overlay" swipeGesture={false}>
      <MenuWithSubsContainer  id="services-menu" 
                              key="services"
                              header="Services"
                              note="SecQR.Me Enabled"
                              pages={appPages} />
    </IonMenu>
  );
};

export default ServicesMenu;